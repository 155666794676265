<template>
  <v-app>
    <HeaderPublic />

    <v-content>
      <router-view :key="key" class="main-content" />
    </v-content>

    <FooterPublic />

  </v-app>
</template>



<script>
import HeaderPublic from "@/components/HeaderPublic"
import FooterPublic from "@/components/FooterPublic"

export default {
  components: {
    HeaderPublic,
    FooterPublic
  },
  data: () => ({

  }),
  computed: {
    key: function () {
      const now = (new Date()).getTime()
      return this.$route.name !== undefined
        ? this.$route.name + '-' + now
        : this.$route + '-' + now
    }
  },
};
</script>

<style>
@media (min-width: 960px)
.container {
    max-width: 940px;
}
.main-content {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
</style>
