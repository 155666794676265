<template>

  <div class="preloader">
    <div v-if="text" class="preloader-text text-center">{{ text }}</div>
    <v-progress-linear
      indeterminate
      :color="iconcolor"
    ></v-progress-linear>
  </div>

</template>

<script>
export default {
  name: 'Preloader',
  props: {
    text: { },
    iconcolor: { type: String, default: "#0277BD" },
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
.preloader {
  width: 300px;
  margin: 100px auto;
  text-align: center;
  color: #01579B;
}
.preloader-text {
  margin: 20px;
}
</style>
