<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  data: () => ({

  }),
};
</script>

<style>
body {
  background-color: #CCC !important;
}
.page-title {
  font-size: 28px;
  margin: 10px;
  color: #656565;
}
.v-application .headline {
  /*font-size: 1.35rem !important;*/
  font-weight: 500 !important;
}
.top-right-close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 23px;
  color: #444;
}
.clickable {
  cursor: pointer;
}
</style>
