<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
      >

      <LoginForm></LoginForm>

      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { config } from '@/config'
import LoginForm from "@/components/LoginForm"
export default {
  data: () => ({
  }),
  components: {
    LoginForm
  },
  created () {
    if (localStorage.getItem(config.localStorageKey.login)) {
      if (this.$route.query && this.$route.query.redirect) {
        this.$router.push({ path: this.$route.query.redirect})
      }
      else {
        this.$router.push({ name: config.defaultRedirect.appHome })
      }
    }
  }
}
</script>
