<template>
  <v-card class="elevation-12">
    <v-toolbar
      color="primary"
      dark
      flat
    >
      <v-toolbar-title>Login form</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form
        ref="form"
        v-model="form.valid"
      >
        <v-text-field
          v-model="form.username"
          label="Username"
          name="wsername"
          prepend-icon="fas fa-user"
          type="text"
          required
          :rules="[v => !!v || 'Please enter username']"
          v-on:keyup="loginIncorrect = false;"
        ></v-text-field>

        <v-text-field
          v-model="form.password"
          id="password"
          label="Password"
          name="password"
          prepend-icon="fas fa-lock"
          type="password"
          required
          :rules="[v => !!v || 'Please enter password']"
          v-on:keyup="loginIncorrect = false;"
        ></v-text-field>
      </v-form>
      <div class="text-center red--text" v-show="loginIncorrect">
        Incorrect username or password
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="!form.valid"
        @click="login"
      >
        Login
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { config } from '@/config'

export default {
  data: () => ({
    loading: false,
    form: {
      valid: true,
      username: null,
      password: null,
    },
    loginIncorrect: false,
  }),
  methods: {

    async login() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loginIncorrect = false
      const apiURL = config.backendLogInURL
      const data = {
        username: this.form.username,
        password: this.form.password
      }
      try {
        const res = await window.axios.post(apiURL, data)
        console.log(res)
        if (res.data.token) {
          localStorage.setItem(config.localStorageKey.login, res.data.token)
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push({ path: this.$route.query.redirect})
          }
          else {
          this.$router.push({ name: config.defaultRedirect.appHome })
          }
        }
      } catch (err) {
        this.loginIncorrect = true
        // console.log(err)
      } finally {
        this.loading = false
      }

    }

  }
}
</script>
