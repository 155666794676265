<template>
  <div>

    <v-navigation-drawer
      class="white"
      v-model="drawer"
      absolute
      temporary
    >

      <v-list-item-content>
        <v-list-item-title class="title px-3 py-1">
         <img src="/images/logo.png" style="max-width: 125px; height: auto;" />
         <div class="float-right clickable grey--text" @click.stop="drawer = false">&#10006;</div>
        </v-list-item-title>
      </v-list-item-content>

      <v-divider></v-divider>

      <v-list nav dense flat>

        <v-list-item :to="{name: 'public-home'}" link class="nav-draw-link">
          <v-list-item-icon>
            <v-icon>fas fa-caret-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{name: 'login'}" link class="nav-draw-link">
          <v-list-item-icon>
            <v-icon>fas fa-caret-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Member Area</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>

    <v-app-bar
      color="black"
      dark
      flat
    >
      <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <a href="/"><img src="/images/logo-white.png" style="height: 45px; width: auto; margin: 8px" /></a>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn small text>
          <router-link class="navbar-link" :to="{name: 'public-home'}">Home</router-link>
          <!-- <a class="navbar-link" href="/#/">Home</a> -->
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn small text>
          <router-link class="navbar-link" :to="{name: 'login'}">Member Area</router-link>
        </v-btn>
      </v-toolbar-items>

    </v-app-bar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      drawer: null,
    }
  }
}
</script>

<style scoped>
.navbar-link {
  color: #CCC !important;
  text-decoration: none !important;
  font-size: 15px;
}
.nav-draw-link {
  color: #444 !important;
}
</style>
