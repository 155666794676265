<template>
  <div>

  <Preloader v-show="loading.pages" text="loading..."></Preloader>

  <v-container>

    <!-- Begin Page Nav -->
    <v-btn-toggle v-if="!loading.pages" class="my-2 elevation-1">
      <v-btn v-for="item in pages" :key="item._id"
        text small
        v-bind:class="{ primary: item._id === pageId }"
        @click="loadCategoriesForPage(item._id)"
        >
        {{ item.title }}
      </v-btn>
      <v-btn
        text small
        @click="showAddPageForm()"
        >
        <v-icon small style="color: #d4d4d4 !important">fas fa-plus</v-icon>
      </v-btn>
     </v-btn-toggle>
     <!-- End Page Nav -->

    <h1 class="text-center page-title" v-if="!loading.pages">
      {{ pageTitle }}
      <span>
        <v-menu left>
          <template v-slot:activator="{ on }">
            <v-btn text icon>
              <v-icon style="color: #e0eef4"
                class="clickable"
                v-on="on"
              >
                fas fa-cog
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item dense @click="showAddCategoryForm(currentPage)">
              <v-list-item-icon class="mr-1"><v-icon small class="mt-1">fas fa-plus</v-icon></v-list-item-icon>
                <v-list-item-content>Add New Category</v-list-item-content>
              </v-list-item>
              <v-list-item dense @click="showEditPageForm(pageId)">
              <v-list-item-icon class="mr-1"><v-icon small class="mt-1">fas fa-edit</v-icon></v-list-item-icon>
              <v-list-item-content>Edit Page</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </h1>

    <Preloader v-show="!loading.pages && loading.categories" text="loading..."></Preloader>

    <v-row v-show="!loading.pages && !loading.categories">

      <v-col
        v-for="layout in layouts" :key="layout"
        md="6" sm="6" xs="12" pa-2
      >
        <v-card
          v-for="category in categories" :key="category._id"
          class="mb-5"
          v-show="category.layout==layout"
        >

          <div class="px-5 pt-5 pb-4">
            <div class="grey lighten-2 pa-2">

              <strong>{{ category.title }}</strong>

              <div class="float-right">
                <v-menu left>
                  <template v-slot:activator="{ on }">
                    <v-icon small
                      class="white--text clickable px-1"
                      v-on="on"
                    >
                      fas fa-ellipsis-v
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item dense @click="showAddLinkForm(category)">
                    <v-list-item-icon class="mr-1"><v-icon small class="mt-1">fas fa-plus</v-icon></v-list-item-icon>
                      <v-list-item-content>Add New Link</v-list-item-content>
                    </v-list-item>
                    <v-list-item dense @click="showEditCategoryForm(category._id)">
                      <v-list-item-icon class="mr-1"><v-icon small class="mt-1">fas fa-edit</v-icon></v-list-item-icon>
                      <v-list-item-content>Edit Category</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

            </div>

          </div>

          <v-simple-table light class="mx-5 my-n2">
          <tbody>
            <tr v-for="link in category.links" :key="link._id">
              <td class="link-item">
                <a :href="link.url" target="_blank" rel="nofollow noopener noreferrer">{{ link.title }}</a>
                <div class="float-right">
                  <v-icon small @click="showEditLinkForm(link._id)">fas fa-edit</v-icon>
                </div>
              </td>
            </tr>
          </tbody>
          </v-simple-table>

          <v-card-actions>
          </v-card-actions>

        </v-card>
      </v-col>

    </v-row>


  </v-container>

  <!-- ----------------------- Begin Add Link Form --------------------------------------------->
  <v-layout justify-center>
    <v-dialog v-model="show.addLinkModal" persistent max-width="600px">

      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="headline">Add Link</span>
          <div class="top-right-close clickable" @click="show.addLinkModal = false">&#10006;</div>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text>

          <v-container>

          <v-form
            ref="addLinkForm"
            v-model="valid.addLinkForm"
          >
            <v-select
              v-model="linkToAdd.category"
              :items="categories"
              item-text="title"
              item-value="_id"
              label="Category"
              return-object
              outlined
              :rules="[v => !!v || 'This field is required']"
            ></v-select>

            <v-text-field
              v-model="linkToAdd.url"
              label="URL"
              outlined
              required
              :rules="[
                v => !!v || 'This field is required',
                v => /^http/.test(v) || 'Must be a valid URL'
              ]"
            ></v-text-field>

            <v-text-field
              v-model="linkToAdd.title"
              label="Title"
              outlined
              required
              :rules="[v => !!v || 'This field is required']"
            ></v-text-field>

            <div class="text-center">

              <v-btn
                :disabled="!valid.addLinkForm || loading.addLink"
                class="mr-4"
                @click="addLink()"
              >
                <span v-show="loading.addLink">Adding ...</span>
                <span v-show="!loading.addLink">Add Link</span>
              </v-btn>

            </div>


          </v-form>

          </v-container>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-layout>
  <!-- -------------------------------- End Add Link Form -------------------------------------->

  <!-- ----------------------- Begin Link Edit Form --------------------------------------------->
  <v-layout justify-center>
    <v-dialog v-model="show.editLinkModal" persistent max-width="600px">

      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="headline">Edit Link</span>
          <div class="top-right-close clickable" @click="show.editLinkModal = false">&#10006;</div>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text>

          <v-container>

          <Preloader v-show="loading.linkToEdit" text="loading..."></Preloader>

          <v-form
            ref="linkEditForm"
            v-if="!loading.linkToEdit && linkToEdit"
            v-model="valid.editLinkForm"
            lazy-validation
          >
            <v-select
              v-model="linkToEdit.category"
              :items="categories"
              item-text="title"
              item-value="_id"
              label="Category"
              return-object
              outlined
              :rules="[v => !!v || 'This field is required']"
            ></v-select>

            <v-text-field
              v-model="linkToEdit.url"
              label="URL"
              outlined
              required
              :rules="[
                v => !!v || 'This field is required',
                v => /^http/.test(v) || 'Must be a valid URL'
              ]"
            ></v-text-field>

            <v-text-field
              v-model="linkToEdit.title"
              label="Title"
              outlined
              required
              :rules="[v => !!v || 'This field is required']"
            ></v-text-field>

            <div class="text-center">

              <v-btn
                :disabled="!valid.editLinkForm || loading.updateLink"
                color="primary"
                class="mr-4"
                @click="updateLink()"
              >
                <span v-show="loading.updateLink">Updating ...</span>
                <span v-show="!loading.updateLink">Update</span>
              </v-btn>

              <v-btn
                color="error"
                @click="confirmDeleteLink()"
              >
                <span v-show="loading.deleteLink">Deleting ...</span>
                <span v-show="!loading.deleteLink">Delete</span>
              </v-btn>

            </div>


          </v-form>

          </v-container>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-layout>
  <!-- -------------------------------- End Link Edit Form -------------------------------------->

  <!-- ----------------------- Begin Add Category Form --------------------------------------------->
  <v-layout justify-center>
    <v-dialog v-model="show.addCategoryModal" persistent max-width="600px">

      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="headline">Add Category</span>
          <div class="top-right-close clickable" @click="show.addCategoryModal = false">&#10006;</div>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text>

          <v-container>

          <v-form
            ref="addCategoryForm"
            v-model="valid.addCategoryForm"
          >
            <v-select
              v-model="categoryToAdd.page"
              :items="pages"
              item-text="title"
              item-value="_id"
              label="Page"
              return-object
              outlined
              :rules="[v => !!v || 'This field is required']"
            ></v-select>

            <v-text-field
              v-model="categoryToAdd.title"
              label="Title"
              outlined
              required
              :rules="[v => !!v || 'This field is required']"
            ></v-text-field>

            <div class="text-center">

              <v-btn
                :disabled="!valid.addCategoryForm || loading.addCategory"
                color="primary"
                class="mr-4"
                @click="addCategory()"
              >
                <span v-show="loading.addCategory">Adding ...</span>
                <span v-show="!loading.addCategory">Add Category</span>
              </v-btn>

            </div>

          </v-form>

          </v-container>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-layout>
  <!-- -------------------------------- End Add Category Form -------------------------------------->

<!-- ----------------------- Begin Category Edit Form --------------------------------------------->
  <v-layout justify-center>
    <v-dialog v-model="show.editCategoryModal" persistent max-width="600px">

      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="headline">Edit Category</span>
          <div class="top-right-close clickable" @click="show.editCategoryModal = false">&#10006;</div>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text>

          <v-container>

          <Preloader v-show="loading.categoryToEdit" text="loading..."></Preloader>

          <v-form
            ref="categoryEditForm"
            v-if="!loading.categoryToEdit && categoryToEdit"
            v-model="valid.editCategoryForm"
            lazy-validation
          >
            <v-select
              v-model="categoryToEdit.page"
              :items="pages"
              item-text="title"
              item-value="_id"
              label="Page"
              return-object
              outlined
              :rules="[v => !!v || 'This field is required']"
            ></v-select>

            <v-text-field
              v-model="categoryToEdit.title"
              label="Title"
              outlined
              required
              :rules="[v => !!v || 'This field is required']"
            ></v-text-field>

            <div class="text-center">

              <v-btn
                :disabled="!valid.editCategoryForm || loading.updateCategory"
                color="primary"
                class="mr-4"
                @click="updateCategory()"
              >
                <span v-show="loading.updateCategory">Updating ...</span>
                <span v-show="!loading.updateCategory">Update</span>
              </v-btn>

              <v-btn
                color="error"
                v-show="!categoryToEdit.links.length"
                @click="confirmDeleteCategory()"
              >
                <span v-show="loading.deleteCategory">Deleting ...</span>
                <span v-show="!loading.deleteCategory">Delete</span>
              </v-btn>

            </div>


          </v-form>

          </v-container>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-layout>
  <!-- -------------------------------- End Category Edit Form -------------------------------------->

  <!-- ----------------------- Begin Add Page Form --------------------------------------------->
  <v-layout justify-center>
    <v-dialog v-model="show.addPageModal" persistent max-width="600px">

      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="headline">Add Page</span>
          <div class="top-right-close clickable" @click="show.addPageModal = false">&#10006;</div>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text>

          <v-container>

          <v-form
            ref="addPageForm"
            v-model="valid.addPageForm"
          >

            <v-text-field
              v-model="pageToAdd.title"
              label="Title"
              outlined
              required
              :rules="[v => !!v || 'This field is required']"
            ></v-text-field>

            <div class="text-center">

              <v-btn
                :disabled="!valid.addPageForm || loading.addPage"
                color="primary"
                class="mr-4"
                @click="addPage()"
              >
                <span v-show="loading.addPage">Adding ...</span>
                <span v-show="!loading.addPage">Add Page</span>
              </v-btn>

            </div>

          </v-form>

          </v-container>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-layout>
  <!-- -------------------------------- End Add Page Form -------------------------------------->

<!-- ----------------------- Begin Page Edit Form --------------------------------------------->
  <v-layout justify-center>
    <v-dialog v-model="show.editPageModal" persistent max-width="600px">

      <v-card>
        <v-card-title class="grey lighten-2">
          <span class="headline">Edit Page</span>
          <div class="top-right-close clickable" @click="show.editPageModal = false">&#10006;</div>
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text>

          <v-container>

          <Preloader v-show="loading.pageToEdit" text="loading..."></Preloader>

          <v-form
            ref="pageEditForm"
            v-if="!loading.pageToEdit && pageToEdit"
            v-model="valid.editPageForm"
            lazy-validation
          >

            <v-text-field
              v-model="pageToEdit.title"
              label="Title"
              outlined
              required
              :rules="[v => !!v || 'This field is required']"
            ></v-text-field>

            <div class="text-center">

              <v-btn
                :disabled="!valid.editPageForm || loading.updatePage"
                color="primary"
                class="mr-4"
                @click="updatePage()"
              >
                <span v-show="loading.updatePage">Updating ...</span>
                <span v-show="!loading.updatePage">Update</span>
              </v-btn>

              <v-btn
                color="error"
                v-show="!pageToEdit.categories.length"
                @click="confirmDeletePage()"
              >
                <span v-show="loading.deletePage">Deleting ...</span>
                <span v-show="!loading.deletePage">Delete</span>
              </v-btn>

            </div>

          </v-form>

          </v-container>

        </v-card-text>

      </v-card>
    </v-dialog>
  </v-layout>
  <!-- -------------------------------- End Page Edit Form -------------------------------------->

  <!-- -------------------------------- Begin Notification Bar ---------------------------------->
  <v-snackbar
    top
    :color="notificationBar.type"
    v-model="notificationBar.show"
  >
    {{ notificationBar.message }}
    <v-btn text @click="notificationBar.show = false">
      Close
    </v-btn>
  </v-snackbar>
  <!-- -------------------------------- End Notification Bar ---------------------------------->

  </div>
</template>

<script>
import Preloader from "@/components/Preloader"
import { config } from '@/config'
export default {
  data: () => ({
    loading: {
      pages: true,
      categories: true,
      linkToEdit: false,
      updateLink: false,
      deleteLInk: false,
      categoryToEdit: false,
      updateCategory: false,
      deleteCategory: false,
      pageToEdit: false,
      updatePage: false,
      deletePage: false,
    },
    layouts: ['left', 'right'],
    pageId: null,
    pages: [],
    categories: [],
    show: {
      addLinkModal: false,
      editLinkModal: false,
      addCategoryModal: false,
      editCategoryModal: false,
      addPageModal: false,
      editPageModal: false,
    },
    valid: {
      addLinkForm: true,
      editLinkForm: true,
      addCategoryForm: true,
      editCategoryForm: true,
      addPageForm: true,
      editPageForm: true,
    },
    linkToAdd: {
      title: null,
      url: null,
      category: null,
    },
    categoryToAdd: {
      title: null,
      page: null
    },
    pageToAdd: {
      title: null,
    },
    linkToEdit: null,
    categoryToEdit: null,
    pageToEdit: null,
    notificationBar: {
      show: false,
      type: 'success', // 'success', 'error'
      message: ''
    },
  }),
  computed: {
    pageTitle: function () {
      if (!this.pageId) {
        return ''
      }
      for (var page of this.pages) {
        if (page._id === this.pageId) {
          return page.title
        }
      }
      return ''
    },
    currentPage: function () {
      if (!this.pageId || !this.pages.length) {
        return null
      }
      let foundPage = null
      for (var page of this.pages) {
        if (page._id === this.pageId) {
          foundPage = {...page}
        }
      }
      return foundPage
    },
  },
  components: {
    Preloader
  },
  methods: {

    async init() {
      this.loading.pages = true
      this.loading.categories = true
      this.loading.linkToEdit = false
      this.pages = []
      this.categories = []
      try {

        // Load Pages
        await this.loadPages()

        // Get saved pageId
        const savedPageId = localStorage.getItem('PAGE_ID')
        if (savedPageId) {
          // console.log('Saved PageID: ' + savedPageId)
          this.pageId = savedPageId
        }
        else {
          this.pageId = this.pages[0]._id
        }

        // Load Categories
        await this.loadCategoriesForPage(this.pageId)

      }
      catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.loading.pages = false
        this.loading.categories = false
      }
    },

    async loadPages() {
      const url = config.backendURL + 'api/pages'
      try {
        const res = await window.axios.get(url)
        // console.log(res)
        this.pages = [...res.data]
        // console.log(this.pages)
      } catch (err) {
        console.log(err)
        throw new Error(err);
      }
    },

    async loadCategoriesForPage(pId) {
      this.loading.categories = true
      // console.log('loading for pageId: ' + pId)
      this.pageId = pId
      localStorage.setItem('PAGE_ID', pId)
      const url = config.backendURL + 'api/links-in-page/' + pId
      try {
        const res = await window.axios.get(url)
        // console.log(res)
        this.categories = [...res.data]
        let totalLinks = 0;
        for (var category of this.categories) {
          totalLinks += category.links.length
          totalLinks += 2
          category.lengthPos = totalLinks
        }
        for (category of this.categories) {
          if(category.lengthPos <= Math.ceil(totalLinks / 2)) {
            category.layout = 'left';
          }
          else {
            category.layout = 'right';
          }
        }
        // console.log(this.categories)
      } catch (err) {
        console.log(err)
        throw new Error(err);
      } finally {
        this.loading.categories = false
      }
    },

    async showEditLinkForm(linkId) {
      this.loading.linkToEdit = true
      this.show.editLinkModal = true
      const url = config.backendURL + 'api/links/' + linkId
      try {
        const res = await window.axios.get(url)
        // console.log(res)
        this.linkToEdit = {...res.data}
        // console.log(this.pages)
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.loading.linkToEdit = false
      }
    },

    showAddLinkForm(category) {
      this.linkToAdd = {
        url: null,
        title: null,
        category: category
      }
      this.show.addLinkModal = true
    },

    async addLink() {
      console.log('addLink')
      this.loading.addLink = true
      if (!this.$refs.addLinkForm.validate()) {
        return;
      }
      const apiURL = config.backendURL + 'api/links'
      const data = {
        url: this.linkToAdd.url,
        title: this.linkToAdd.title,
        category: this.linkToAdd.category._id,
      }
      try {
        await window.axios.post(apiURL, data)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Link Added successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.addLinkModal = false
        this.loading.addLink = false
        this.$refs.addLinkForm.reset()
        this.valid.addLinkForm = true
      }
    },

    async updateLink() {
      // console.log(this.linkToEdit)
      this.loading.updateLink = true
      if (!this.$refs.linkEditForm.validate()) {
        return;
      }
      const apiURL = config.backendURL + 'api/links/' + this.linkToEdit._id
      const data = {
        url: this.linkToEdit.url,
        title: this.linkToEdit.title,
        category: this.linkToEdit.category._id,
      }
      try {
        await window.axios.put(apiURL, data)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Link updated successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.editLinkModal = false
        this.loading.updateLink = false
        this.$refs.linkEditForm.reset()
      }
    },

    async confirmDeleteLink() {
      const res = await this.$confirm('Do you really want to delete?', { title: 'Warning', icon: '' })
      if (res) {
        this.deleteLink()
      }
    },

    async deleteLink() {
      // console.log('deleteLink: ' + this.linkToEdit._id)
      this.loading.deleteLink = true
      if (!this.linkToEdit._id) {
        return;
      }
      const apiURL = config.backendURL + 'api/links/' + this.linkToEdit._id
      try {
        await window.axios.delete(apiURL)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Link deleted successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.editLinkModal = false
        this.loading.deleteLink = false
        this.$refs.linkEditForm.reset()
      }
    },

    showAddCategoryForm(page) {
      this.categoryToAdd = {
        title: null,
        page: page
      }
      this.show.addCategoryModal = true
    },

    async addCategory() {
      console.log('addCategory')
      this.loading.addCategory = true
      if (!this.$refs.addCategoryForm.validate()) {
        return;
      }
      const apiURL = config.backendURL + 'api/categories'
      const data = {
        title: this.categoryToAdd.title,
        page: this.categoryToAdd.page._id,
      }
      // console.log(data)
      try {
        await window.axios.post(apiURL, data)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Category Added successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.addCategoryModal = false
        this.loading.addCategory = false
        this.$refs.addCategoryForm.reset()
        this.valid.addCategoryForm = true
      }
    },

    async showEditCategoryForm(categoryId) {
      this.loading.categoryToEdit = true
      this.show.editCategoryModal = true
      const url = config.backendURL + 'api/categories/' + categoryId
      try {
        const res = await window.axios.get(url)
        console.log(res)
        this.categoryToEdit = {...res.data}
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.loading.categoryToEdit = false
      }
    },

    async updateCategory() {
      this.loading.updateCategory = true
      if (!this.$refs.categoryEditForm.validate()) {
        return;
      }
      const apiURL = config.backendURL + 'api/categories/' + this.categoryToEdit._id
      const data = {
        title: this.categoryToEdit.title,
        page: this.categoryToEdit.page._id,
      }
      // console.log(data)
      try {
        await window.axios.put(apiURL, data)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Category updated successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.editCategoryModal = false
        this.loading.updateCategory = false
        this.$refs.categoryEditForm.reset()
      }
    },

    async confirmDeleteCategory() {
      const res = await this.$confirm('Do you really want to delete?', { title: 'Warning', icon: '' })
      if (res) {
        this.deleteCategory()
      }
    },

    async deleteCategory() {
      // console.log('deleteLink: ' + this.linkToEdit._id)
      this.loading.deleteCategory = true
      if (!this.categoryToEdit._id) {
        return;
      }
      const apiURL = config.backendURL + 'api/categories/' + this.categoryToEdit._id
      try {
        await window.axios.delete(apiURL)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Category deleted successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.editCategoryModal = false
        this.loading.deleteCategory = false
        this.$refs.categoryEditForm.reset()
      }
    },

    showAddPageForm() {
      this.categoryToAdd = {
        title: null,
      }
      this.show.addPageModal = true
    },

    async addPage() {
      // console.log('addPage')
      this.loading.addPage = true
      if (!this.$refs.addPageForm.validate()) {
        return;
      }
      const apiURL = config.backendURL + 'api/pages'
      const data = {
        title: this.pageToAdd.title
      }
      // console.log(data)
      try {
        await window.axios.post(apiURL, data)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Page Added successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.addPageModal = false
        this.loading.addPage = false
        this.$refs.addPageForm.reset()
        this.valid.addPageForm = true
      }
    },

    async showEditPageForm(pageId) {
      this.loading.pageToEdit = true
      this.show.editPageModal = true
      const url = config.backendURL + 'api/pages/' + pageId
      try {
        const res = await window.axios.get(url)
        // console.log(res)
        this.pageToEdit = {...res.data}
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.loading.pageToEdit = false
      }
    },

    async updatePage() {
      this.loading.updatePage = true
      if (!this.$refs.pageEditForm.validate()) {
        return;
      }
      const apiURL = config.backendURL + 'api/pages/' + this.pageToEdit._id
      const data = {
        title: this.pageToEdit.title
      }
      // console.log(data)
      try {
        await window.axios.put(apiURL, data)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Page updated successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.editPageModal = false
        this.loading.updatePage = false
        this.$refs.pageEditForm.reset()
      }
    },

    async confirmDeletePage() {
      const res = await this.$confirm('Do you really want to delete?', { title: 'Warning', icon: '' })
      if (res) {
        this.deletePage()
      }
    },

    async deletePage() {
      // console.log('deleteLink: ' + this.linkToEdit._id)
      this.loading.deletePage = true
      if (!this.pageToEdit._id) {
        return;
      }
      const apiURL = config.backendURL + 'api/pages/' + this.pageToEdit._id
      try {
        await window.axios.delete(apiURL)
        // console.log(res)
        this.init()
        this.showNotification('success', 'Page deleted successfully!')
      } catch (err) {
        console.log(err)
        this.showNotification('error', 'Error occurred!')
      } finally {
        this.show.editPageModal = false
        this.loading.deletePage = false
        this.$refs.pageEditForm.reset()
      }
    },

    showNotification(type, message) {
      this.notificationBar.message = message
      this.notificationBar.type = type   // 'success' or 'error'
      this.notificationBar.show = true
    }

  },
  created () {
    this.init()
  }
}
</script>

<style scoped>
.v-data-table td {
  height: 32px !important;
  padding: 0px 9px !important;
}
.v-data-table tbody tr {
  border-left: 1px solid rgba(0, 0, 0, 0.10);
  border-right: 1px solid rgba(0, 0, 0, 0.10);
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}
.v-data-table td a {
  text-decoration: none;
  color: #075E50;
  font-family: Roboto, arial, "Heiti SC", "Microsoft Yahei", simsun, sans-serif !important;
}
.link-item .v-icon.v-icon {
  color: #FFF !important;
}

.link-item:hover .v-icon.v-icon {
  color: #BABABA !important;
}
</style>
