import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios"
import VuetifyConfirm from 'vuetify-confirm'
import { config } from '@/config'

Vue.use(VuetifyConfirm, { vuetify })

window.axios = axios

window.axios.interceptors.request.use(function (request) {
    if (request.url.includes(config.backendURL)) {
      const localSavedJwtToken = localStorage.getItem(config.localStorageKey.login)
      if (localSavedJwtToken) {
        request.headers.Authorization = `Bearer ${localSavedJwtToken}`
        // console.log('VueResource added jwtToken')
      }
    }
    return request;
  }, function (error) {
    return Promise.reject(error)
  });


window.axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response) {
      // console.log('error found')
      // console.log(error.response)
      if (error.config.url && error.config.url != config.backendLogInURL) {
        const status = error.response.status
        if (status && (status === 401 || status === 419 || status === 440) ) {
          // console.log('401/419/440 response received. Removing localstorage')
          localStorage.removeItem(config.localStorageKey.login)
          location.reload()
        }
      }
    }
    return Promise.reject(error)
  });


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
