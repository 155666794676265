// const backendURL = 'http://localhost:5007/'
const backendURL = 'https://api.lianber.com/'

export const config = {
  backendURL: backendURL,
  backendLogInURL: backendURL + 'auth',
  localStorageKey: {
    login: 'userJWT',
  },
  defaultRedirect: {
    publicHome: 'public-home',
    appHome: 'bookmark'
  }
}
